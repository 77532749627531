import React, { useState, useEffect, createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Preloader from './shared/Preloader';
import Home from './pages/Home/Home';
import AgeCalc from './pages/AgeCalc/AgeCalc';
import TTDL from './pages/TTDL/TTDL';
import NotFound from './shared/NotFound';
import Dev from './pages/Dev/Dev';

export const InitializeContext = createContext(null as any);

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  const [theme, setTheme] = useState<boolean>(false);

  useEffect(() => {
    setTheme(JSON.parse(window.localStorage.getItem("tbRaselPorto") || false as any));
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const toggleTheme = () => {
    setTheme(!theme);
    window.localStorage.setItem("tbRaselPorto", !theme as any);
  };

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <div data-theme={theme ? 'dark' : 'light'} className="bg-base-100">
      <InitializeContext.Provider value={{ toggleTheme, theme }}>
        {
          loading ? (
            <Preloader />
          ) :
            (
              <>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/dev" element={<Dev />} />
                  <Route path="/ageCalc" element={<AgeCalc />} />
                  <Route path="/ttdl" element={<TTDL />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <Toaster />
              </>
            )
        }
      </InitializeContext.Provider>
    </div>
  );
}

export default App;
