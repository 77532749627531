import React, { useContext, useState } from 'react'
import Slider from "react-slick";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import { quote } from "../../assets";
import { teamMembers } from '../../data/teamMembers';
import { InitializeContext } from '../../App';
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs';
import toast from 'react-hot-toast';

function SampleNextArrow(props: any) {
          const { theme } = useContext(InitializeContext);
          const { onClick } = props;
          return (
                    <div
                              className={`w-14 h-12 ${theme ? 'text-white bg-[#212428]' : 'bg-[#ECF0F3] text-black'} duration-300 rounded-md text-2xl hidden md:flex justify-center items-center absolute top-0 right-0 shadow-xl cursor-pointer z-10`}
                              onClick={onClick}
                    >
                              <HiArrowRight />
                    </div>
          );
}

function SamplePrevArrow(props: any) {
          const { theme } = useContext(InitializeContext);
          const { onClick } = props;
          return (
                    <div
                              className={`w-14 h-12 ${theme ? 'text-white bg-[#212428]' : 'bg-[#ECF0F3] text-black'} duration-300 rounded-md text-2xl hidden md:flex justify-center items-center absolute top-0 right-20 shadow-xl cursor-pointer z-10`}
                              onClick={onClick}
                    >
                              <HiArrowLeft />
                    </div>
          );
}

export default function TeamMembers() {
          const { theme } = useContext(InitializeContext);
          const [dotActive, setDocActive] = useState(0);

          const settings = {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />,
                    beforeChange: (prev: any, next: any) => {
                              setDocActive(next);
                    },
                    appendDots: (dots: any) => (
                              <div
                                        style={{
                                                  borderRadius: "10px",
                                                  padding: "10px",
                                        }}
                              >
                                        <ul
                                                  style={{
                                                            display: "flex",
                                                            gap: "15px",
                                                            justifyContent: "center",
                                                            marginTop: "20px",
                                                  }}
                                        >
                                                  {" "}
                                                  {dots}{" "}
                                        </ul>
                              </div>
                    ),
                    customPaging: (i: any) => (
                              <div
                                        className='mt-5'
                                        style={
                                                  i === dotActive
                                                            ? {
                                                                      width: "12px",
                                                                      height: "12px",
                                                                      color: "blue",
                                                                      background: "#ff014f",
                                                                      borderRadius: "50%",
                                                                      cursor: "pointer",
                                                            }
                                                            : {
                                                                      width: "12px",
                                                                      height: "12px",
                                                                      color: "blue",
                                                                      background: "gray",
                                                                      borderRadius: "50%",
                                                                      cursor: "pointer",
                                                            }
                                        }
                              ></div>
                    ),
          };
          return (
                    <section
                              id="team"
                              className={`px-4 lg:px-0 md:py-16 pb-24 md:pb-10 border-b ${theme ? 'text-white bg-[#212428] border-[#212428]' : 'bg-[#ECF0F3] text-black border-[#ECF0F3]'}`}>
                              <div className='text-center flex-col justify-center items-center md:flex'>
                                        <h1 className="text-2xl md:text-4xl font-bold leading-tight">
                                                  My Team Members
                                        </h1>
                              </div>
                              <div className="max-w-6xl mx-auto mt-5 md:mt-10">
                                        <Slider {...settings}>
                                                  {teamMembers.map((member, index) => (
                                                            <div className="w-full shadow-inner lg:shadow-none rounded-xl" key={index}>
                                                                      <div className="w-full h-auto flex flex-col lg:flex-row justify-between">
                                                                                <div className="w-full lg:w-[35%] h-full bg-bgOne p-4 md:p-8 lg:p-4 rounded-lg lg:shadow-inner flex flex-col md:flex-row lg:flex-col gap-8 justify-center md:justify-start lg:justify-center">
                                                                                          <img
                                                                                                    className="md:h-48 lg:h-full rounded-lg object-cover"
                                                                                                    src={member.image}
                                                                                                    alt={member.name}
                                                                                          />
                                                                                          <div className="w-full flex flex-col justify-end">
                                                                                                    <h3 className="text-2xl font-bold">{member.name}</h3>
                                                                                                    <p className="text-sm tracking-wide py-2">
                                                                                                              {member.position}
                                                                                                    </p>
                                                                                                    <p className="text-base font-semibold tracking-wide pb-2">
                                                                                                              {member.company}
                                                                                                    </p>
                                                                                                    <div className='flex justify-start items-center gap-4 mt-2 md:mt-5'>
                                                                                                              <a href={member.facebook} target='_blank' rel="noreferrer" className={`border cursor-pointer py-3 px-4 shadow-xl rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                                                                                                                        <BsFacebook />
                                                                                                              </a>
                                                                                                              <a href={member.instagram} target='_blank' rel="noreferrer" className={`border cursor-pointer py-3 px-4 shadow-xl rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                                                                                                                        <BsInstagram />
                                                                                                              </a>
                                                                                                              {member.youtube ? (
                                                                                                                        <a href={member.youtube} target='_blank' rel="noreferrer" className={`border cursor-pointer py-3 px-4 shadow-xl rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                                                                                                                                  <BsYoutube />
                                                                                                                        </a>
                                                                                                              ) : (
                                                                                                                        <span onClick={() => toast.error('Youtube link not found')} className={`border cursor-pointer py-3 px-4 shadow-xl rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                                                                                                                                  <BsYoutube />
                                                                                                                        </span>
                                                                                                              )}
                                                                                                    </div>
                                                                                          </div>
                                                                                </div>
                                                                                <div className="w-full lg:w-[60%] h-full flex flex-col justify-between -mt-5 md:mt-0">
                                                                                          <img className="w-0 md:w-0 lg:w-32 pb-10 md:pb-0" src={quote} alt="quote" />
                                                                                          <div className="w-full h-[70%] -mt-6 lg:-mt-0 lg:py-10 bg-bgOne rounded-lg lg:shadow-inner p-4 lg:p-8 flex flex-col justify-center gap-4 lg:gap-8">
                                                                                                    <div className="flex flex-col justify-between lg:items-center lg:py-6 lg:border-b">
                                                                                                              <div>
                                                                                                                        <h3 className="text-2xl md:text-3xl text-center font-bold tracking-wide">
                                                                                                                                  About
                                                                                                                        </h3>
                                                                                                              </div>
                                                                                                    </div>
                                                                                                    <p className="text-base font-medium tracking-wide leading-6">
                                                                                                              {member.description}
                                                                                                    </p>
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                  ))}
                                        </Slider>
                              </div>
                    </section>
          );
}