import React from "react";
import notFound from "../assets/404.png";
import { Link } from "react-router-dom";
import { HiOutlineHome } from "react-icons/hi";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-cover">
      <img src={notFound} alt="" className="bg-cover" />
      <Link to="/" className="absolute bottom-0 right-0 mr-10 mb-10">
        <button className="glass font-semibold py-3 px-3 md:py-4 md:px-5 rounded-lg flex justify-center items-center gap-2">
          <HiOutlineHome className="text-xl" /> Go Home
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
