import React, { useContext } from 'react'
import { InitializeContext } from '../../App';
import aboutImg from '../../assets/me/about.jpg';
import { Link } from 'react-scroll';
import { BiLogoTelegram } from 'react-icons/bi';

const Fade = require("react-reveal/Fade");

export default function About() {
  const { theme } = useContext(InitializeContext);

  return (
    <section id={'about'} className={`md:py-20 pb-10 md:pb-5 border-b ${theme ? 'text-white bg-[#212428] border-[#212428]' : 'bg-[#ECF0F3] text-black border-[#ECF0F3]'}`}>
      <div className='hidden md:flex justify-center items-center mx-auto'>
        <h1 className="text-2xl md:text-4xl font-bold leading-tight">
          About Me
        </h1>
      </div>
      <div className="hero">
        <div className="hero-content flex-col justify-between lg:flex-row">
          <Fade bottom distance="20px">
            <div className="w-full md:w-2/3 lg:w-1/3 shadow-xl rounded-xl overflow-hidden">
              <div className="outline-none p-1 md:p-3">
                <img
                  src={aboutImg}
                  className="rounded-xl w-full"
                  alt="Tb Rasel"
                />
              </div>
            </div>
          </Fade>
          <Fade bottom distance="30px">
            <div className="lg:w-1/2 pt-5 lg:pt-0 leading-loose">
              <h1 className="text-2xl md:hidden md:text-4xl font-bold leading-tight">
                About Me
              </h1>
              <p className="py-3 mt-4">
                Md. Rasel Islam Sabuj professionally known as Tb Rasel in the internet world, He was born on December 28, 2004 in Kurigram, Bangladesh. He is an Entrepreneur, Digital Marketer, YouTuber and Musician by profession. He is the founder of Mirror-LTD Music.
              </p>
              <p className="pb-3">
                Tb Rasel studied at Kurigram Government College from 2021. His parents name Md. Abdul Motaleb and Mst. Ruzina Begum. Currently he living in Kurigram, Bangladesh with his family.
              </p>
              <p className="pb-3">
                Tb Rasel says, success doesn't find you, you have to find yourself and find success. It is better to try and fail than to sit without trying So keep trying. If unsuccessful, try again. Success will come sometime.
              </p>
              <div className='flex flex-col lg:flex-row lg:gap-2 py-3'>
                <div className='flex flex-col gap-1 lg:gap-0'>
                  <p><span className='font-semibold'>Full Name: </span>Md. Rasel Islam Sabuj</p>
                  <p><span className='font-semibold'>Known As: </span>Tb Rasel</p>
                  <p><span className='font-semibold'>Place of birth: </span>Kurigram, Bangladesh</p>
                </div>
                <div className='flex flex-col gap-1 lg:gap-0 mt-1 lg:mt-0'>
                  <p><span className='font-semibold'>Education: </span>Kurigram Government College (2021)</p>
                  <p><span className='font-semibold'>Parents: </span>Abdul Motaleb, Ruzina Begum</p>
                </div>
              </div>
              <div className='flex justify-start items-center lg:mt-3'>
                <Link to='contact' smooth={true} duration={1000} className={`flex items-center gap-2 border cursor-pointer py-3 px-5 shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                  Contact <BiLogoTelegram />
                </Link>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}
