import React, { useContext, useState } from "react";
import { BsArrowUpCircle } from "react-icons/bs";
import { InitializeContext } from "../App";

const ScrollButton = () => {
  const { theme } = useContext(InitializeContext);
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <span className={`scroll-btn fixed cursor-pointer ${theme ? 'text-white' : 'text-black'} text-[2.5rem] w-100 z-[999] left-[84%] md:left-[91%] lg:left-[96%] bottom-[55px] md:bottom-[70px] h-[20px]`}>
      <BsArrowUpCircle
        className="rounded-full"
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </span>
  );
};

export default ScrollButton;
