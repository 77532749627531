import React from 'react'

export default function AgeCalc() {
  return (
    <div className='iframe-container'>
      <iframe
        src='https://www.fastjobsearchers.com/tool/age-calculator.html'
        title="Embedded Website"
        width="100%"
        frameBorder="0"
        allowFullScreen
        className='h-[calc(100vh-0rem)] md:h-[calc(100vh-0rem)]'
      />
    </div>
  )
}
