import React, { useContext } from 'react'
import { InitializeContext } from '../App';

export default function Footer() {
          const { theme } = useContext(InitializeContext);
          return (
                    <footer className={`flex flex-col justify-center items-center h-12 pb-5 ${theme ? 'text-white bg-[#212428] border-[#212428]' : 'bg-[#ECF0F3] text-black border-[#ECF0F3]'}`}>
                              <p className='text-sm'>Copyright &copy; {new Date().getFullYear()} <span className={`${theme ? 'hover:text-warning' : 'hover:text-primary'} duration-300 cursor-pointer font-stabillo text-xl`}>Tb Rasel</span> - All Rights Reserved.</p>
                    </footer>
          )
}
