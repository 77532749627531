import React, { useContext } from 'react'
import { InitializeContext } from '../../../App';

export default function ServicesCard({ title, src }: any) {
          const { theme } = useContext(InitializeContext);
          return (
                    <div className="w-full p-4 md:pt-5 rounded-xl shadow-xl flex flex-col group">
                              <div className="w-full h-[80%] overflow-hidden rounded-lg">
                                        <img
                                                  className="w-full h-60 object-cover group-hover:scale-110 duration-300"
                                                  src={src}
                                                  alt="src"
                                        />
                              </div>
                              <div className="w-full mt-5 mb-2 flex flex-col gap-3">
                                        <p className={`text-sm tracking-wide ${theme ? 'text-warning' : 'text-primary'}`}>
                                                  Available
                                        </p>
                                        <h3 className="text-base md:text-xl font-bold uppercase">
                                                  {title}
                                        </h3>
                              </div>
                    </div>
          );
}