import team1 from '../assets/me/me.jpg';
import team2 from '../assets/team-members/kiron.jpg';
import team3 from '../assets/team-members/tahsin.jpg';
import team4 from '../assets/team-members/naymul.jpg';

export const teamMembers = [
          {
                    "id": 1,
                    "name": "Tb Rasel",
                    "position": "Founder & CEO",
                    "image": team1,
                    "facebook": "https://www.facebook.com/ImdRasel35",
                    "instagram": "https://www.instagram.com/tb_rasel",
                    "youtube": "https://www.youtube.com/@tbraselbd",
                    "company": "Mirror LTD Music",
                    "workingSince": "Jan 2021 - Present",
                    "description": "Tb Rasel (Md. Rasel Islam Sabuj) is a Bangladeshi Musician, Digital Marketer And Youtuber. He is the founder and CEO of Mirror-LTD Music."
          },
          {
                    "id": 2,
                    "name": "Toufiq Hasan Kiron",
                    "position": "MERN Stack Developer",
                    "image": team2,
                    "facebook": "https://www.facebook.com/toufiqhasankiron",
                    "instagram": "https://www.instagram.com/toufiqhasankiron",
                    "youtube": "https://www.youtube.com/@toufiqhasankiron",
                    "company": "Mirror LTD Music",
                    "workingSince": "Jan 2022 - Present",
                    "description": "Toufiq Hasan Kiron is known professionally as MERN Stack Developer, Bangladeshi Author & Musical artist. He is aspiring to be a full stack developer with a full stack of snacks."
          },
          {
                    "id": 3,
                    "name": "Tahsin Rabby",
                    "position": "Head Of Marketing",
                    "image": team3,
                    "facebook": "https://www.facebook.com/tahsiin.rabby",
                    "instagram": "https://instagram.com/tahsiin.rabby",
                    "youtube": "",
                    "company": "Mirror LTD Music",
                    "workingSince": "Jan 2021 - Present",
                    "description": "Tahsin Rabby is a professional digital marketer. He started marketing in 2019. He is the main Digital Marketer of Mirror-LTD Music."
          },
          {
                    "id": 4,
                    "name": "Naymul Haque Antu",
                    "position": "Head Of Music Composer",
                    "image": team4,
                    "facebook": "https://www.facebook.com/SkAntu20",
                    "instagram": "https://instagram.com/antuantu46",
                    "youtube": "https://youtube.com/@DjAntu",
                    "company": "Mirror LTD Music",
                    "workingSince": "Jan 2021 - Present",
                    "description": "Md. Naymul Haque Antu is a bangladeshi youtuber and musician. He started Youtubing from 2018 and he is a successful YouTuber. He is the Music master of Mirror-LTD Music."
          },
];