import React, { useContext, useState, useEffect } from 'react'
import { RiMenu4Fill } from 'react-icons/ri'
import { BiLogoTelegram } from 'react-icons/bi'
import { InitializeContext } from '../App';
import { Link } from "react-scroll";
import { navLinksData } from '../data/navLinksData';

export default function Navbar() {
  const { toggleTheme, theme } = useContext(InitializeContext);
  const [scrollY, setScrollY] = useState<number>(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollY(position);
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY]);

  const NavMenus = (
    <>

      {navLinksData.map(({ _id, title, link }) => (
        <li
          className="menu-item lg:mr-3"
          key={_id}
        >
          <Link
            activeClass={`text-white ${theme ? 'bg-warning' : 'bg-primary'}`}
            className='py-4 px-6 my-1 uppercase glass'
            to={link}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {title}
          </Link>
        </li>
      ))}
    </>
  )

  return (
    <section className={`sticky top-0 z-50 w-full duration-300 ${theme ? 'text-white bg-[#212428] border-[#212428]' : 'bg-[#ECF0F3] text-black border-[#ECF0F3]'} ${scrollY > 50 ? "shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]" : "border-b"}`}>
      <div className="navbar md:w-3/4 py-3 md:py-4 lg:py-2 lg:w-2/3 mx-auto">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <RiMenu4Fill size={30} />
            </label>
            <ul tabIndex={0} className={`menu menu-compact dropdown-content mt-4 md:mt-5 p-2 shadow-xl rounded-box w-72 ${theme ? 'text-white bg-[#212428]' : 'bg-[#ECF0F3] text-black'}`}>
              {NavMenus}
            </ul>
          </div>
          <span onClick={() => window.scrollTo({
            top: 0,
            behavior: "smooth",
          })} className={`btn bg-transparent hover:bg-transparent border-none ${theme ? 'text-white' : 'text-black'} normal-case text-2xl md:text-3xl lg:text-4xl flex justify-center items-center gap-2 font-stabillo`} title='Tb Rasel'>Tb Rasel</span>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            {NavMenus}
          </ul>
        </div>
        <div className="navbar-end hidden lg:flex gap-3">
          <Link to='contact' smooth={true} duration={1000} className={`p-3 hover:bg-primary glass hover:text-white duration-500 cursor-pointer rounded-lg uppercase shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] flex items-center gap-2`}><BiLogoTelegram /> Hire Me</Link>
          <span onClick={toggleTheme} className='cursor-pointer'>
            {
              theme ? (
                <i className='bx bx-sun text-xl'></i>
              ) : (
                <i className='bx bx-moon text-xl'></i>
              )
            }
          </span>
        </div>
        <div className="navbar-end lg:hidden pr-5">
          <span onClick={toggleTheme} className='cursor-pointer'>
            {
              theme ? (
                <i className='bx bx-sun text-xl'></i>
              ) : (
                <i className='bx bx-moon text-xl'></i>
              )
            }
          </span>
        </div>
      </div>
    </section>
  )
}
