import React from 'react'
import me from '../assets/me/me.jpg'

export default function Preloader() {
          return (
                    <div className='flex justify-center items-center h-screen'>
                              <div className='flex flex-col justify-center items-center'>
                                        <img src={me} alt="me" className='w-20 h-20 rounded-full object-cover animate-pulse' />
                                        <h1 className='text-3xl md:text-4xl mt-5 font-stabillo animate-pulse'>
                                                  Tb Rasel
                                        </h1>
                              </div>
                    </div>
          )
}
