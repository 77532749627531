import React from 'react'

export default function TTDL() {
          return (
                    <div>
                              <iframe
                                        src='https://savett.cc/en/'
                                        title="Embedded Website"
                                        width="100%"
                                        frameBorder="0"
                                        allowFullScreen
                                        className='h-[calc(100vh-0rem)] md:h-[calc(100vh-0rem)]'
                              />
                    </div>
          )
}
