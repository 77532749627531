import React, { useContext } from 'react'
import { InitializeContext } from '../../../App';
import { servicesOne, servicesTwo, servicesThree, servicesFour, servicesFive } from "../../../assets/index";
import ProjectsCard from './ServicesCard';

export default function Services() {
        const { theme } = useContext(InitializeContext);
        return (
                <section id={'services'} className={`md:py-16 pb-16 md:pb-10 border-b ${theme ? 'text-white bg-[#212428] border-[#212428]' : 'bg-[#ECF0F3] text-black border-[#ECF0F3]'}`}>
                        <div className='text-center flex-col justify-center items-center md:flex'>
                                <h1 className="text-2xl md:text-4xl font-bold leading-tight">
                                        Our Services
                                </h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-3 md:px-0 xl:gap-14 w-full md:w-11/12 lg:w-2/3 mx-auto mt-5">
                                <ProjectsCard
                                        title="Music Distribution"
                                        src={servicesOne}
                                />
                                <ProjectsCard
                                        title="Google Knowledge Panel"
                                        src={servicesTwo}
                                />
                                <ProjectsCard
                                        title="YouTube Artist Verify"
                                        src={servicesFive}
                                />
                                <ProjectsCard
                                        title="Website Development"
                                        src={servicesThree}
                                />
                                <ProjectsCard
                                        title="Capcut Reward Withdraw"
                                        src={servicesFour}
                                />
                        </div>
                </section>
        )
}
