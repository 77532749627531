import React from 'react'
import Banner from './Banner'
import About from './About'
import Contact from './Contact'
import Footer from '../../shared/Footer'
import TeamMembers from './TeamMembers'
import ScrollButton from '../../shared/ScrollButton'
import Services from './Services/Services'
import FreeServices from './FreeServices/FreeServices'
import Navbar from '../../shared/Navbar'

export default function Home() {
  return (
    <>
      <Navbar />
      <Banner />
      <About />
      <Services />
      <FreeServices />
      <TeamMembers />
      <Contact />
      <Footer />
      <ScrollButton />
    </>
  )
}
