export const navLinksData = [
          {
                    _id: 1001,
                    title: "Home",
                    link: "home",
          },
          {
                    _id: 1002,
                    title: "About",
                    link: "about",
          },
          {
                    _id: 1003,
                    title: "Services",
                    link: "services",
          },
          {
                    _id: 1004,
                    title: "Team",
                    link: "team",
          },
          {
                    _id: 1005,
                    title: "Contact",
                    link: "contact",
          },
];