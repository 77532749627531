import React, { useContext, useState } from 'react'
import { InitializeContext } from '../../App';
import contactImg from '../../assets/me/contact.JPEG'
import { BsArrowRight, BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs';
import toast from 'react-hot-toast';

const initForm = {
  name: '',
  email: '',
  subject: '',
  message: ''
}

export default function Contact() {
  const { theme } = useContext(InitializeContext);
  const [form, setForm] = useState(initForm);

  const handleChange = (e: any) => {
    setForm((oldForm) => ({
      ...oldForm,
      [e.target.name]: e.target.value
    }));
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.name || !form.email || !form.subject || !form.message) return toast.error('Please fill up the form');
    toast.success('Message Sent Successfully');
    setForm(initForm);
  }

  return (
    <section id={'contact'} className={`md:pt-16 md:pb-5 border-b ${theme ? 'text-white bg-[#212428] border-[#212428]' : 'bg-[#ECF0F3] text-black border-[#ECF0F3]'}`}>
      <div className='text-center flex-col justify-center items-center md:flex'>
        <h1 className="text-2xl md:text-4xl font-bold leading-tight">
          Contact With Me
        </h1>
      </div>

      <div className="flex flex-col justify-center lg:flex-row gap-10 md:gap-16 mb-10 px-4 lg:px-0 mt-5">
        <div className='w-full lg:w-1/4 rounded-xl shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] overflow-hidden'>
          <div className="outline-none p-4 md:p-8">
            <img
              src={contactImg}
              className="rounded-xl w-full"
              alt="contactImg"
            />
          </div>
          <div className='px-4 md:px-8 pb-4 md:pb-8'>
            <h1 className='text-2xl md:text-3xl font-bold'>Md. Rasel Islam Sabuj</h1>
            <p className='py-4 font-semibold text-sm md:text-base'>Musician - Digital Marketer - Youtuber</p>
            <p className='pb-4'>I am available for freelance work. Connect with me via and call in to my account.</p>
            <p>Phone: <a href="https://api.whatsapp.com/send?phone=+17075748645&text=Hello, I need help!" className={`font-semibold ${theme ? 'hover:text-warning' : 'hover:text-primary'}`}> +1 (707) 574‑8645</a> (Whatsapp)</p>
            <p>Email: <a href="mailto:contact@tbrasel.com" className={`font-semibold ${theme ? 'hover:text-warning' : 'hover:text-primary'}`}>contact@tbrasel.com</a></p>

            <p className='text-start text-sm my-5 pt-6 font-semibold'>Find With Me</p>
            <div className='flex justify-start items-center gap-4'>
              <a href='https://www.facebook.com/ImdRasel35' target='_blank' rel="noreferrer" className={`border cursor-pointer py-4 px-5 shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                <BsFacebook size={20} />
              </a>
              <a href='https://www.instagram.com/tb_rasel' target='_blank' rel="noreferrer" className={`border cursor-pointer py-4 px-5 shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                <BsInstagram size={20} />
              </a>
              <a href='https://www.youtube.com/@tbraselbd' target='_blank' rel="noreferrer" className={`border cursor-pointer py-4 px-5 shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-xl ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>
                <BsYoutube size={20} />
              </a>
            </div>
          </div>
        </div>
        <div className='w-full lg:w-1/3 rounded-xl shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] overflow-hidden p-4 md:p-8'>
          <form onSubmit={handleSubmit} className='flex flex-col justify-center gap-5'>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">YOUR NAME</span>
              </label>
              <input type="text" className="input input-bordered py-7 w-full" name='name' value={form.name} onChange={handleChange} />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">EMAIL</span>
              </label>
              <input type="text" className="input input-bordered py-7 w-full" name='email' value={form.email} onChange={handleChange} />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">SUBJECT</span>
              </label>
              <input type="text" className="input input-bordered py-7 w-full" name='subject' value={form.subject} onChange={handleChange} />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">YOUR MESSAGE</span>
              </label>
              <textarea style={{ resize: 'none', height: '200px' }} typeof="text" className="input input-bordered pt-3 w-full" name='message' value={form.message} onChange={handleChange} />
            </div>
            <div>
              <button type='submit' className={`border cursor-pointer py-4 px-5 shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-xl flex items-center gap-2 ${theme ? 'hover:bg-warning hover:border-warning' : 'hover:bg-primary hover:border-primary glass'} hover:text-white duration-500`}>Send Message <BsArrowRight /></button>
            </div>
          </form>
        </div>

      </div>

    </section>
  )
}
