import React from 'react'
import { FiExternalLink } from 'react-icons/fi';

export default function FreeServicesCard({ title, live, src }: any) {
          return (
                    <div className="w-full p-4 md:pt-5 rounded-xl shadow-xl flex flex-col group">
                              <div className="w-full h-[80%] overflow-hidden rounded-lg">
                                        <img
                                                  className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
                                                  src={src}
                                                  alt="src"
                                        />
                              </div>
                              <div className="w-full mt-5 mb-3 flex flex-col gap-3">
                                        <div className="flex items-center justify-between">
                                                  <h3 className="text-base md:text-xl font-bold uppercase">
                                                            {title}
                                                  </h3>
                                                  <a href={live} target='_blank' rel="noreferrer" className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-white hover:text-designColor duration-300 cursor-pointer">
                                                            <FiExternalLink />
                                                  </a>
                                        </div>
                              </div>
                    </div>
          );
}